<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="BoxHeight plr15">
      <div class="text333 f15 fw700 pt20 pb20">{{ $t("my.baseInfo") }}</div>
      <!-- 基本信息表单 -->
      <el-form
        ref="InfoFormRef"
        :model="InfoForm"
        label-width="80px"
        label-position="left"
        class="edit"
        :disabled="isdisabled"
      >
        <el-form-item :label="$t('my.avatar')">
          <el-upload
            class="avatar-uploader textr"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              :src="InfoForm.logo"
              class="avatar"
              onerror="this.src='https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2100401123,2895311668&fm=26&gp=0.jpg'"
            />
          </el-upload>
        </el-form-item>
        <el-form-item :label="$t('reg.username')">
          <el-input
            v-model="InfoForm.name"
            :placeholder="$t('my.inputName')"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('reg.age')">
          <el-input v-model="InfoForm.age"
                    :placeholder="$t('my.inputAge')"></el-input>
        </el-form-item> -->
        <el-form-item :label="$t('reg.comName')">
          <el-input
            v-model="InfoForm.companyName"
            :placeholder="$t('my.inputComName')"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('reg.comAddr')">
          <el-input v-model="InfoForm.address"
                    :placeholder="$t('my.inputComAddr')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('reg.position')">
          <el-input v-model="InfoForm.postion"
                    :placeholder="$t('my.inputPosition')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('reg.experYears')">
          <el-input v-model="InfoForm.experYears"
                    :placeholder="$t('my.inputWorkYears')"></el-input>
        </el-form-item> -->
        <el-form-item :label="$t('reg.email')">
          <el-input
            v-model="InfoForm.email"
            :placeholder="$t('my.inputEmail')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="textc">
        <el-button type="primary" @click="edit()" class="wp80 f16 Btn">{{
          changeTxt
        }}</el-button>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/commonHead.vue";
import Footer from "../../components/footer.vue";
var tips1 = "上传头像图片只能是 JPG 格式!";
var tips2 = "上传头像图片大小不能超过 2MB!";
var tips3 = "提示";
var tips4 = "发送成功！";
var tips5 = "修改成功";
var tips6 = "修改失败：";
var tips7 = "修改";
var tips8 = "保存";
export default {
  components: { Header, Footer },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var validatePassNew = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.InfoFormTwo.newPassword) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      InfoForm: {
        id: "1231231233",
        logo: "", //头像
        name: "乔连连", // 姓名
        age: "24", // 年龄
        phone: "13245123654", // 联系方式
        companyName: "云蓝科技", // 公司名称
        address: "安徽省合肥市蜀山区", // 公司地址
        postion: "行政", // 职位
        experYears: "2", //经验年数
        email: "123456@qq.com", //邮箱
      }, // 基本信息表单
      InfoFormTwo: {
        phone: "",
        code: "",
        password: "123456",
        oldPassword: "",
        newPassword: "",
        checkPasd: "",
      },
      uploadUrl: "",
      isdisabled: true,
      isdisabled2: true,

      //表单验证
      InfoFormTwoRules: {
        newPassword: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        checkPasd: [
          { required: true, validator: validatePassNew, trigger: "blur" },
        ],
      },
      changeTxt: tips7,
    };
  },
  methods: {
    // 上传头像
    handleAvatarSuccess(res, file) {
      this.InfoForm.logo = file.response.data.url;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error(tips1);
      }
      if (!isLt2M) {
        this.$message.error(tips2);
      }
      return isJPG && isLt2M;
    },

    // 修改资料
    edit() {
      if (this.changeTxt == tips7) {
        this.changeTxt = tips8;
        this.isdisabled = false;
      } else if (this.changeTxt == tips8) {
        var that = this;
        this.$http
          .post("member/updateBaseInfo", this.InfoForm)
          .then(function (res) {
            if (200 == res.data.code) {
              that.isdisabled = true;
              var member = res.data.data;
              member.isLogin = true;
              localStorage.setItem("member", JSON.stringify(member));
              that.$notify.success({
                title: tips3,
                message: tips5,
                showClose: true,
              });
            } else {
              that.$notify.error({
                title: tips3,
                message: tips6 + res.data.message,
                showClose: true,
              });
            }
          });
      }
    },
    handleCodeClick() {
      var sendData = {};
      sendData.phone = this.InfoForm.phone;
      sendData.type = "changePwd";
      var that = this;
      this.$http.post("authCode/sendMsg", sendData).then(function (res) {
        that.countTime();
        if (200 == res.data.code) {
          that.$notify.success({
            title: tips3,
            message: tips4,
            showClose: true,
          });
        }
      });
    },
    changeLanguage() {
      if (this.$store.getters.language == "zh") {
        tips1 = "上传头像图片只能是 JPG 格式!";
        tips2 = "上传头像图片大小不能超过 2MB!";
        tips3 = "提示";
        tips4 = "发送成功！";
        tips5 = "修改成功";
        tips6 = "修改失败：";
        tips7 = "修改";
        tips8 = "保存";
      }
      if (this.$store.getters.language == "en") {
        tips1 = "Upload avatar picture only JPG format!";
        tips2 = "Upload avatar picture size must not exceed 2MB!";
        tips3 = "Prompt";
        tips4 = "Sent successfully!";
        tips5 = "Modify the success";
        tips6 = "Modify the failure：";
        tips7 = "Change";
        tips8 = "Save";
      }
      if (this.$store.getters.language == "ja") {
        tips1 = "画像はjpg形式でしかアップロードできません。";
        tips2 = "写真のサイズは2 mbを超えてはいけません!";
        tips3 = "提示";
        tips4 = "発送成功!";
        tips5 = "修正に成功する";
        tips6 = "修正に失敗する：";
        tips7 = "直す";
        tips8 = "保存";
      }
      if (this.isdisabled == false) {
        this.changeTxt = tips8;
      } else {
        this.changeTxt = tips7;
      }
    },
  },
  created() {
    this.changeLanguage();
    this.uploadUrl = this.$http.defaults.baseURL + "upload/uploadFile";
    // console.log("=========" + this.uploadUrl);
    this.InfoForm = JSON.parse(localStorage.getItem("member"));
    this.InfoFormTwo.phone = this.InfoForm.phone;
    this.InfoFormTwo.password = this.InfoForm.password;
  },
  mounted() {
    this.changeLanguage();
  },
  updated() {
    this.changeLanguage();
  },
};
</script>

<style scoped>
.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}
.Btn {
  margin: 40px auto;
}
.edit >>> .el-input__inner {
  padding-right: 10px;
}
.edit2 >>> .el-input__inner {
  padding-right: 25px;
}

.edit2 >>> .el-input__suffix {
  top: 3px;
  right: -12px;
}
.edit2 >>> .code .el-input__suffix {
  top: 5px;
  right: 5px;
}
</style>